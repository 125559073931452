.ng-dropdown-panel {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  left: 0
}

.ng-dropdown-panel.ng-select-bottom {
  top: 100%;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-color: #e6e6e6;
  margin-top: -1px
}

.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px
}

.ng-dropdown-panel.ng-select-top {
  bottom: 100%;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-color: #e6e6e6;
  margin-bottom: -1px
}

.ng-dropdown-panel.ng-select-top .ng-dropdown-panel-items .ng-option:first-child {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px
}

.ng-dropdown-panel .ng-dropdown-header {
  border-bottom: 1px solid #ccc;
  padding: 5px 7px
}

.ng-dropdown-panel .ng-dropdown-footer {
  border-top: 1px solid #ccc;
  padding: 5px 7px
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
  user-select: none;
  padding: 8px 10px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled {
  cursor: default
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked {
  background-color: #f5faff
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected.ng-option-marked {
  background-color: #ebf5ff;
  font-weight: 600
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  padding: 8px 10px
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  color: #333;
  background-color: #ebf5ff
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label {
  font-weight: 600
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: #f5faff;
  color: #333
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
  color: #ccc
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
  padding-left: 22px
}

[dir="rtl"] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
  padding-right: 22px;
  padding-left: 0
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
  font-size: 80%;
  font-weight: 400;
  padding-right: 5px
}

[dir="rtl"] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
  padding-left: 5px;
  padding-right: 0
}

[dir="rtl"] .ng-dropdown-panel {
  direction: rtl;
  text-align: right
}
